import { Auth } from "aws-amplify";
import React, { useState, useRef } from "react";
import url from "../../util/routeURL";
import { BrowserRouter as Link } from "react-router-dom";
import MyAccountModal from "./MyAccountModal";
import useOnClickOutside from "../../components/useOnClickOutside";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { Backdrop } from "@material-ui/core";

const NavAuth = () => {
  const [validSession, setValidSession] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setOpenModal(false);
  });

  Auth.currentSession()
    .then(() => {
      setValidSession(true);
    })
    .catch(err => {
      setValidSession(false);
      console.error(err);
    });

  // makes it so the dropdown blends in with the color of the background
  if (
    validSession &&
    (window.location.pathname === "/" ||
      window.location.pathname === "/registerkit" ||
      window.location.pathname === "/settings" ||
      window.location.pathname === "/health-profile" ||
      window.location.pathname === "/manage-subscription" 
      )
  ) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",

        }}
      >
        
        <div ref={ref} className="pink site-nav__link site-nav__link--main ">
          <button
            onClick={() => setOpenModal(!openModal)}
            id="myaccount_button"
            className={
              openModal
                ? `site-nav__label parent-modal button myAccount_border`
                : `site-nav__label parent-modal button`
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              My Account &nbsp;
              <KeyboardArrowDownRoundedIcon style={{ fontSize: 25 }} />
            </div>
          </button>
          <MyAccountModal
            openModal={openModal}
            toggleModal={() => setOpenModal(!openModal)}
          />
        </div>
      </div>
    );
  } else if (
    validSession &&
    (window.location.pathname === "/inbox" ||
      window.location.pathname === "/sent" ||
      window.location.pathname === "/history")
  ) {
    return (
      <div ref={ref} className="green site-nav__link site-nav__link--main ">
        <button
          onClick={() => setOpenModal(!openModal)}
          id="myaccount_button"
          className={
            openModal
              ? `site-nav__label parent-modal button myAccount_border`
              : `site-nav__label parent-modal button`
          }
        >
          My Account &nbsp;
          <img src="./img/chevron-down.png" alt="down arrow" />
        </button>
        <MyAccountModal
          openModal={openModal}
          toggleModal={() => setOpenModal(!openModal)}
        />
      </div>
    );
  } else {
    return (
      <Link className="site-nav__link site-nav__link--main" to={url.login}>
        <span className="site-nav__label">My Account</span>
      </Link>
    );
  }
};

export default NavAuth;
